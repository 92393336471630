// Inside a component
import BodyMain from './body-main';
import Faqs from './faqs';
import Hero from './hero';
import HomePageNavbar from '../../components/navbar';
import RollOfLogos from './roll_of_logos';
import SolutionFive from './solution_five';
import SolutionOne from './solution_one';
import SolutionThree from './solution_three';
import SolutionTwo from './solution_two';
import Testimonials from './testimonials';
import Footer from '../../components/footer';
 
function LandingPage() {
  return (
    <div>
        <HomePageNavbar/>
        <Hero/>
        <RollOfLogos/>
        <SolutionOne/>
        <BodyMain/>
        <SolutionTwo/>
        <SolutionThree/>
        <Testimonials/>
        <Faqs/>
        <SolutionFive/>
        <Footer/> 
        
    </div>
  );
}

export default LandingPage;
