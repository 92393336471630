import DialogModal from "../modal";
import "./form.css";
import { useState } from "react";

const DataDeletionRequestForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reason: "",
  });

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setStatus(
        "Kindly note that your account deletion request will be reviewed by our team, after which a confirmation mail will sent to your email address before your data is deleted"
      );
      setFormData({ name: "", email: "", reason: "" }); // Reset form
      setLoading(false);
      setShowModal(true);
    }, 3000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='data-deletion-page'>
      <h2 className='page-title'>Request for Data Deletion</h2>
      <p>
        Use this form to request the deletion of your personal data from our
        records. Please ensure the information provided matches the data we have
        on file. Our team will review your request and respond promptly.
      </p>
      <p style={{ color: "red" }}>
        <strong>Important:</strong> Data deletion requests are subject to our
        privacy policy and applicable legal requirements.
      </p>
      <form className='data-deletion-form' onSubmit={handleSubmit}>
        <div>
          <label>
            Name <span>*</span>
          </label>
          <input
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            placeholder='Enter your full name'
            className='form-input'
          />
        </div>
        <div>
          <label>
            Email <span>*</span>
          </label>
          <input
            type='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
            placeholder='Enter your email address'
            className='form-input'
          />
        </div>
        <div>
          <label>Reason for Deletion Request</label>
          <textarea
            name='reason'
            value={formData.reason}
            onChange={handleChange}
            placeholder='Briefly explain why you are requesting data deletion'
            className='form-text'
          />
        </div>
        <p style={{ fontSize: "14px", marginBottom: "10px" }}>
          By submitting this request, you acknowledge that data deletion may be
          irreversible and that we may retain certain information as required by
          law.
        </p>
        <button
          type='submit'
          className='form-submit'
          disabled={loading}
          style={{ cursor: loading ? "not-allowed" : "pointer" }}
        >
          {loading ? <div className='loading-anim' /> : "Submit Request"}
        </button>
      </form>
      <DialogModal
        showModal={showModal}
        handleClose={handleCloseModal}
        message={status}
      />
    </div>
  );
};

export default DataDeletionRequestForm;
