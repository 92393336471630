import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const restoreScroll = (event) => {
      if (event.persisted || event.type === "popstate") {
        // Do nothing; let the browser handle it
        return;
      }
      // Scroll to top for normal navigation
      window.scrollTo(0, 0);
    };

    window.addEventListener("popstate", restoreScroll);
    return () => {
      window.removeEventListener("popstate", restoreScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
