import './header.css';


function AboutHeader () {
    return (<div>
        <br/>
        <br/>
        <br/>
        <div className="pt-5"/>
        <div className="pt-2"/>

        <center>
        <div className='about-title'>About iNPAY</div>
            <div className='p-3 d-none d-md-block' />
            <div className='about-subtitle d-none d-md-block'>We are on a mission to give you the best feel at banking</div>
        </center>
        
    </div>)
}

export default AboutHeader