import Footer from "../../components/footer";
import AboutHeader from "./header/header";
import AboutBody from "./body/body";
import HomePageNavbar from "../../components/navbar";

 
function AboutPage() {
  return (
    <div>
        <HomePageNavbar/>
        <AboutHeader/>
        <AboutBody/>
        <Footer/>
    </div>
  );
}

export default AboutPage;