function TermsText() {
  return (
    <div className='privacy-text-wrap'>
      <h2 id='title'>Terms of Service</h2>
      <p>
        By signing up for an account on iNPAY platform (the “Platform”), you are
        deemed a customer and agree to this Terms of Service (the “Agreement”).
        PLEASE READ THIS TERMS OF SERVICE CAREFULLY BEFORE SIGNING UP AS A
        CUSTOMER. If you do not agree to any or all of these Terms of Service,
        DO NOT SIGN UP FOR AN ACCOUNT!
      </p>
      <p>Last updated: December 5, 2024.</p>
      <h3 id='section-1'>1. About Us</h3>
      <p>
        iNTECH Management Limited (“we”, “us” or “our”) provides a platform
        called iNPAY. iNPAY (the “Platform”) gives access to financial and other
        lifestyle services such as banking, insurance, travels, wallet
        management, event tickets, payment gateway and others (“Products”).
      </p>
      <p>
        Banking services offered on the Platform are provided by
        <strong> VFD Microfinance Bank (RC 1209088)</strong>, an entity licensed
        by the Central Bank of Nigeria. Banking services are offered to only
        customers in Nigeria. Other services offered on the Platform are
        provided by the respective named service providers. By signing up for
        any of the Products, you consent to this Terms of Service as well as the
        respective terms of service of the relevant named service providers.
      </p>
      <h3 id='section-2'>2. The Scope of our Relationship with You</h3>
      <p>
        The Platform gives you access to financial and other lifestyles
        services. Accordingly, when you sign up on the Platform, we will aim to
        set up a bank account for you with VFD MFB (for Nigerian customers) and
        also offer you other value-added services.
      </p>
      <h3 id='section-3'>3. Creating an Account</h3>
      <p>
        To access any of the Products on the Platform, you need to set up an
        iNPAY account. To set up your iNPAY account, you will provide us with
        your personal details such as your name, email address, gender details
        and contact number. When you provide us with these details, we will open
        an iNPAY account for you and also activate the process of opening a bank
        account for you with VFD Microfinance Bank (“VFD MFB”). To complete the
        process of setting up your bank account with VFD MFB, you will need to
        provide us with relevant Know-Your-Customer (“KYC”) information such as
        BVN and government issued identity documents to enable us verify your
        identity.
      </p>
      <p>
        Once the KYC process is completed, we will issue you a VFD MFB account
        number and you can then access banking services on the iNPAY Platform.
      </p>
      <p>
        Please note that we can only open a VFD MFB bank account for you with
        your consent and subject to the following conditions:
      </p>
      <ul className='bullet-outline'>
        <li>You are at least 18 years old; ○You are a Nigerian;</li>
        <li>You meet our technology requirements for account set up;</li>
        <li>You do not have an existing account with us;</li>
        <li>You have provided all required KYC information; and</li>
        <li>
          We have been able to verify your KYC information on approved channels.
        </li>
      </ul>

      <p>
        When you choose to complete the process of setting up a VFD MFB bank
        account, you authorize us to process payments on your behalf via your
        VFD MFB account. If you would like to withdraw this authorization at any
        point in time, you can reach out to us via the channels outlined below
        to close your account.
      </p>
      <p>
        For KYC purposes, please note that the VFD MFB accounts are tiered as
        follows:
      </p>
      <table className='requirements'>
        <thead>
          <tr>
            <th>Account type</th>
            <th>Documentations Requirements</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic</td>
            <td>Name, address, phone number and BVN</td>
          </tr>
          <tr>
            <td>High value</td>
            <td>
              Name, address, phone number, BVN and a valid means of ID (Voter’s
              card, National ID card, Driver’s license and international
              passport)
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Please note that in each of the above cases, we will take a digital
        passport photograph of you using our technology, but this will only be
        done with your consent.
      </p>
      <p>
        Also, in compliance with extant banking regulations, VFD MFB is obliged
        to conduct ongoing due diligence on all bank accounts. Accordingly, you
        have the sole responsibility of ensuring that your contact address,
        email and phone number are up to date in your profile. You agree to
        indemnify us and VFD MFB for any loss suffered as a result of any false
        information or error in any information you provide to us.
      </p>
      <h3 id='section-4'>4. Account Security</h3>
      <p>
        You agree not to allow anyone else to have or use your password details
        and to comply with all reasonable instructions we may issue regarding
        account access and security. In the event you share your password, we
        will not be liable to you for losses or damages arising in this regard.
        You will also take all reasonable steps to protect the security of the
        devicesthrough which you access the iNPAY Platform as well as your VFD
        MFB account (including, without limitation, using PIN and or password
        protected personally configured device functionality to access the
        Products and not sharing your device with other people).
      </p>
      <p>
        Further, in respect of your bank account, you authorize VFD MFB to apply
        restrictions on your account and report to law enforcement agencies
        including the Economic and Financial Crimes Commission, the Nigerian
        Financial Intelligence Unit, the Nigerian Police Force etc. if your
        account is used for or associated with fraudulent activities.
      </p>
      <p>
        You also consent to VFD MFB reporting to the Nigeria Interbank
        Settlement Systems Plc (NIBSS) for update on the Watchlist Database of
        the Nigerian banking industry and the CBN if your account is associated
        with any fraudulent activity.
      </p>
      <h3 id='section-5'>5. Using the Platform iNPAY Account</h3>
      <p>
        VFD MFB is regulated by the CBN and therefore required to comply with
        applicable Guidelines, Regulations, Circular etc. issued by the CBN. In
        addition, VFD MFB is also obliged to comply with applicable laws in
        Nigeria.
      </p>
      <p>
        The Platform does not deal in or offer cryptocurrencies to Nigerian
        customers due to the CBN restrictions. VFD MFB does not also facilitate
        payments for cryptocurrency and other related transactions. Accordingly,
        you confirm that you will not use your VFD MFB account to deal in,
        trade, transact or receive any payment relating to cryptocurrencies and
        related transactions.
      </p>
      <p>
        You also confirm that your accounts shall only be used legal purposes
        and not for any illegal or criminal activities, including but not
        limited to obtaining funds under false pretense or any other fraudulent
        activity in contravention of extant laws.
      </p>
      <h3 id='section-6'>6. Closing an Account</h3>
      <p>
        Please know that you can close your account at any time and at no cost.
        If you will like to close your account, please reach out to us. You will
        however remain liable for any obligations related to your account with
        us. Once the account is closed, you will not be able to access any of
        the Products.
      </p>
      <p>
        We also reserve the right to close, suspend, freeze or limit access to
        your account if –
      </p>
      <ul className='bullet-outline'>
        <li>
          the information you provide to us does not comply with regulatory
          requirements;
        </li>
        <li>you breach this Terms of Service or the general Terms of Use;</li>
        <li>you create a risk or possible legal exposure to us;</li>
        <li>we are required to do so by law; or</li>
        <li>
          if our investigation reveals that you have engaged in fraudulent or
          suspicious activity with your account.
        </li>
      </ul>

      <p>The above list is inclusive and not exhaustive.</p>
      <p>
        VFD MFB is required to comply with international sanctions laws and
        regulations as well as local laws and regulations applicable to banks.
        Accordingly, VFD MFB may close your account if you breach any sanction
        regulations or where you have a connection with a person or entity
        listed on any local and international sanctions list.
      </p>
      <h3 id='section-7'>7. Communications and Notifications</h3>
      <p>
        Our primary language of communicating with you will be English Language.
        Also, by opening an account with us, you authorize us to send
        information via text (SMS), email and push notifications. Information we
        send to you via any of the above media are only for your purpose and we
        will not assume liability for any loss or damage arising as a result of
        unauthorized access to such information.
      </p>
      <p>
        Further, by opening an iNPAY account, you agree to waive the CBN
        mandatory requirement for SMS alerts in favor of push notifications. You
        hereby agree to indemnify iNPAY against all losses, damages, claims,
        demands and expenses whatsoever which may be incurred, imposed or
        suffered by iNPAY as well as against all actions, proceedings or claims
        (including attorney’s fees) whether civil or criminal that may be
        brought against iNPAY by reason of such notifications.
      </p>
      <h3 id='section-8'>8. Data Privacy and Security Compliance</h3>
      <p>
        We agree to comply at all times with all data privacy and security
        requirements under the Nigeria Data Protection Act (“NDPA”) and other
        data protection laws regulating our provision of services to you.
      </p>
      <p>
        We are responsible for the security and protection of personal data,
        which we collect and process. Accordingly, we implement access control
        measures, security protocols and standards including the use of
        encryption and firewall technologies to ensure that personal data is
        kept safe and secure on our systems in compliance with extant laws. We
        also implement periodical security updates to ensure that our security
        infrastructures are in compliance with reasonable industry standards.
      </p>
      <p>
        We acknowledge that you own all your personal data. By opening an
        account with us, you consent to us processing your personal information
        for the following purposes:
      </p>
      <ol className='lower-alpha'>
        <li>
          Providing the Products as well as other products and services which
          you may apply for subject to this Terms of Service;
        </li>
        <li>
          Providing or sharing information with you on new products and services
          that may interest you. (This may be in the form of newsletters we may
          share with you to give you updates on our products and services. You
          can unsubscribe from our newsletter using the link provided for such
          purpose in each newsletter we send to you).
        </li>
        <li>
          Carrying out statistical and other analysis to identify potential
          markets and trends, evaluate and improve our business (this includes
          improving existing and developing product and services);
        </li>
        <li>
          complying with applicable legal requirements and assisting law
          enforcement agencies by responding to requests for the disclosure of
          information in accordance with local laws; and
        </li>
        <li>
          any other purpose for which consent has been specifically provided by
          you.
        </li>
      </ol>

      <h3 id='section-9'>9. Trademark License</h3>
      <p>
        You shall not use any of the iNPAY trademarks used to identify our
        services (the “Trademarks”) for any purpose whatsoever without our prior
        written consent. You agree that you will not at any time during or after
        this Agreement assert or claim any interest in or do anything that may
        adversely affect the validity of any Trademark or any other trademark,
        trade name or product designation belonging to or licensed to iNTECH
        MANAGEMENT LIMITED (including, without limitation registering or
        attempting to register any Trademark or any such other trademark, trade
        name or product designation).
      </p>
      <h3 id='section-10'>10. Intellectual Property</h3>
      <p>
        We do not grant any right or license to any intellectual property rights
        by implication, estoppel or otherwise other than those expressly
        mentioned in this Agreement.
      </p>
      <h3 id='section-11'>11. Privacy Policy</h3>
      <p>
        We are committed to managing your Personal Data in line with industry
        best practices. You can read our Privacy Policy to understand how we use
        your personal data and the steps we take to protect the same.
      </p>
      <h3 id='section-12'>12. Violation of Terms and Conditions</h3>
      <p>
        If, in our sole discretion, we believe that you may have engaged in any
        violation of this terms of service, we may (with or without notice to
        you) take such actions as we deem appropriate to mitigate risk to us and
        any impacted third parties and to ensure compliance with these terms.
        Such actions may include, without limitation:
      </p>
      <ul>
        <li>
          Suspending, restricting or terminating your access to and use of the
          Products (which includes suspending your account);
        </li>
        <li>Taking legal action against you;</li>
        <li>
          Contacting and disclosing information related to such violations to
          law enforcement or regulatory agencies and other third parties that
          may have been impacted by such violations; or
        </li>
        <li>
          Assessing against you any fees, penalties, assessments or expenses
          (including reasonable attorneys’ fees) that we may incur as a result
          of such violations, which you agree to pay promptly upon notice.
        </li>
      </ul>

      <h3 id='section-13'>13. Limitation of Liability</h3>
      <p>
        In no event shall iNTECH MANAGEMENT LIMITED or its directors, employees,
        licensors, agents, suppliers or service providers be liable to you or
        any other third party for any loss or damages howsoever arising
        (directly or indirectly) whether in tort, contract, negligence or any
        other basis including but not limited to special, indirect, incidental,
        consequential, exemplary or punitive damages, damages for loss of
        profits, revenue, or good will, which may arise from your use or any
        person’s use, access, misuse or inability to use the Platform or any
        links to third party websites or any of the Materials contained therein,
        reliance on the information contained on the Platform, any technical,
        hardware or software failure of any kind, the interruption, error,
        omission, delay in operation, computer viruses, even if we have been
        advised of the possibility of such damages.
      </p>
      <h3 id='section-14'>14. Indemnity</h3>
      <p>
        You hereby irrevocably agree to defend, indemnify, and hold harmless
        INTECH MANAGEMENT LIMITED, its officers, directors, shareholders,
        employees, independent contractors and agents, from and against any and
        all claims, actions, liabilities, costs, or demands, including without
        limitation legal and accounting fees, for all damages directly,
        indirectly, and or consequentially resulting or allegedly resulting from
        your misuse of the Platform or any of the Products and services made
        available on the same, or your breach of any of this Terms of Service or
        any intellectual property right or proprietary right infringement claim
        made by a third party against INTECH MANAGEMENT LIMITED in connection
        with your use of the Platform. We shall promptly notify you of any such
        claim, proceeding or suit, and you shall be responsible for its defense
        at your expense. INTECH MANAGEMENT LIMITED may participate in the
        defense of such claim or defense but is not obligated to do so.
      </p>
      <h3 id='section-15'>15. Updates, Modifications & Amendments</h3>
      <p>
        We may need to update, modify or amend these Terms of Service as our
        technology evolves. We reserve the right to make changes to these Terms
        at any time by giving notice to you on this page.
      </p>
      <p>
        We advise that you check this page often, referring to the date of the
        last modification on the page. If you object to any of the changes to
        these Terms of Service, you must cease using the services immediately.
      </p>
      <h3 id='section-16'>16. Governing Law and Jurisdiction</h3>
      <p>
        These terms of service and all matters arising out of the same shall be
        governed by and are to be construed in accordance with the laws of the
        Federal Republic of Nigeria.
      </p>
      <p>
        We shall make an effort to settle all disputes amicably. Any dispute
        arising out of these Terms of Service, which cannot be settled, by
        mutual agreement within a period of one (1) month from the time such
        dispute arose shall be referred to arbitration by a single arbitrator at
        the Lagos Court of Arbitration (“LCA”) and governed by the Lagos State
        Arbitration Law 2009. The arbitrator shall be appointed by both of us
        (we and you). Where both of us are unable to agree on the choice of an
        arbitrator, the choice of arbitration shall be referred to the Chairman
        of the Chartered Institute of Arbitrator, UK (Nigerian branch) on
        application by us or you. The findings of the arbitrator and subsequent
        award shall be binding on both of us. Each of us shall bear our
        respective costs in connection with the arbitration. Venue for the
        arbitration shall be Lagos, Nigeria.
      </p>
      <h3 id='section-17'>17. Miscellaneous</h3>
      <p>
        You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing. We reserve the right
        to transfer, assign or sub-contract the benefit of the whole or part of
        any rights or obligations under these Terms of Service to any third
        party.
      </p>
    </div>
  );
}

export default TermsText;
