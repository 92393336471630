import Footer from "../../../components/footer";
import PrivacyText from "./privacy-text";
import HomePageNavbar from "../../../components/navbar";
import TableOfContents from "../../../components/table-of-contents";
import "../styles/legal-general.css";

function PrivacyPage() {
  return (
    <div className='page-toc-wrap'>
      <HomePageNavbar />
      <TableOfContents />

      {/* Main Content */}
      <div className='privacy-page-container'>
        <PrivacyText />
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPage;
