import FaqItems from "./faq_items";
import "./faqs.css";

function Faqs() {
  return (
    <div className='container pt-5'>
      <center>
        <div className='cb'>FAQ'S</div>
        <div className='gq'>Got questions?</div>
        <div className='gta pb-5'>
          Get the answers to your questions about iNPAY.
        </div>
      </center>
      <FaqItems
        question='What is iNPAY WALLET?'
        answer='iNPAY Wallet is a secure and convenient digital wallet designed to streamline your financial transactions. Whether you need to send money to a friend, pay for goods and services, or manage your finances on the go, iNPAY Wallet provides a seamless solution. Additionally, it allows you to add users to your wallet, making it easy to manage finances with friends and family.'
      />
      <FaqItems
        question='What is a Pay ID / iNPAY ID?'
        answer='A Pay ID or iNPAY ID is a unique identifier used within the iNPAY system to facilitate secure and efficient financial transactions. It simplifies the process of sending and receiving money by eliminating the need for bank account numbers or other sensitive financial information.'
      />
      <FaqItems
        question='Are There Any Hidden Fees with iNPAY?'
        answer='No, there are no hidden fees with iNPAY. Transparency and fairness are at the core of our service.'
      />
    </div>
  );
}

export default Faqs;
