function TermsText() {
  return (
    <div className='privacy-text-wrap'>
      <h2 id='heading'>Terms of Use</h2>
      <p>
        This Privacy Policy includes important information about your personal
        data and we encourage you to read it carefully. Last updated: December
        5, 2024.
      </p>
      <h3 id='section-1'>1. Terms of Use Definition</h3>
      <p>Definition</p>
      <ul className='bullet-outline'>
        <li>
          In this T&C, the following words shall have the following meanings: -
        </li>
        <li>
          <strong>“User”</strong> refers to the person who accesses and uses the
          Platforms.
        </li>
        <li>
          <strong>“Third Party Service Providers”</strong> refer to third party
          entities whose products and services are accessible through the
          Platforms.
        </li>
        <li>
          <strong>“We” “Us” “Our”</strong> refers to Intech Management Limited
          (iNTECH).
        </li>
        <li>
          <strong>“You” “Your”</strong> refers to the User.
        </li>
      </ul>

      <p>
        All products and services provided on the Platforms are subject to the
        terms and conditions of the applicable agreements governing their use.
        This T&C is meant to regulate your access to the Platforms and it should
        be read together with the applicable terms and conditions governing any
        transaction(s), product(s) and or service(s) provided on the Platforms
        (as may be issued by the respective named service providers).
      </p>
      <h3 id='section-2'>2. Use of Material</h3>
      <p>
        The contents of the Platforms, including but not limited to text,
        artwork, names, logos, trademarks, service marks, software, video,
        music, sound, photographs, graphics, illustrations and other material
        (the “Materials”) are protected by the applicable copyright laws. You
        may only access and use the Platforms, or any of the information or
        Materials provided on the Platforms in a lawful manner. We authorize you
        to view and download a single copy of the Material on the Platforms
        solely for your personal, non-commercial use. You may not sell or modify
        the Material or reproduce, display, distribute, or otherwise use the
        Material in any way for any non private, public or commercial purpose
        without our prior written consent. If you violate any of the provisions
        of this T&C, your permission to use the Material automatically
        terminates and you must immediately destroy any copies you have made of
        the Material.
      </p>
      <h3 id='section-3'>3. Privacy Policy</h3>
      <p>
        We are committed to managing your Personal data according to applicable
        laws and international best practices. Please read our Privacy Policy to
        understand how we use your personal data and the measures we have in
        place to protect your personal data.
      </p>
      <h3 id='section-4'>4. Acceptable Use Policy</h3>
      <p>
        You are solely responsible for complying with all applicable laws
        relating to your use of the Platforms. By accessing the Platforms, you
        agree to comply with this T&C and our Acceptable Use Policy, which is
        accessible via our Acceptable Use page.
      </p>
      <h3 id='section-5'>5. Intellectual Property Rights</h3>
      <p>
        The Materials and content accessible from the Platforms as well as all
        trademarks, service marks, and logos displayed on the Platforms is the
        proprietary information and valuable intellectual property of iNECH or
        the Third Party Service Providers or other parties that provided the
        Materials to iNECH. iNECH retains all right, title, and interest in the
        Materials. Accordingly, the Materials may not be modified, copied,
        distributed, broadcast, displayed, licensed, transferred, republished,
        uploaded, posted, sold commercially or transmitted in any way without
        the prior written consent of iNECH or the relevant Third Party Service
        Provider except that you may print out a copy of the Materials solely
        for your personal use. In doing so, you may not remove or alter, or
        cause to be removed or altered, any copyright, trademark, trade name,
        service mark, or any other proprietary notice appearing on any of the
        Materials. Modification or use of the content except as expressly
        provided in this T&C violates iNECH's intellectual property rights and
        you may be in breach of statutory or common law rights which could be
        the subject of legal action. Neither license, right, title nor
        intellectual property rights are transferred or granted to you by access
        to the Platforms. All contents included on the Platforms, including but
        not limited to text, graphics, photographs, video and audio clips,
        streaming data, animation, images, downloadable materials, data
        compilations and software are the properties of iNECH or its content
        suppliers or the respective named Third Party Service Providers and are
        protected by applicable copyright and trademark laws.
      </p>
      <p>
        iNTECH disclaims all liability which may arise from any unauthorized
        reproduction or use of the contents of the Platforms.
      </p>
      <h3 id='section-6'>6. No Warranties</h3>
      <p>
        You expressly agree that use of the Platforms or any Materials contained
        on the same is at your own and sole risk. The materials and information
        on the Platforms, including but not limited to services, products,
        information, data, text, graphics, links or other items, are provided by
        iNECH on an “as is” and “as available” basis. References to material and
        information contained on the Platforms include such material and
        information provided by third parties.
      </p>
      <p>
        We do not make any express or implied warranties, representations or
        endorsements including but not limited to any warranties of title,
        non-infringement of proprietary or third party rights, merchantability,
        usefulness, operation, completeness, timeliness, correctness, accuracy,
        satisfactory quality, reliability, fitness for a particular purpose in
        respect of the Platforms, the material, information and or functions
        therein and we expressly disclaim liability for errors and omissions in
        such materials, information and or functions. Without derogation of the
        above and or the terms and conditions of the applicable agreements
        governing all the products and services offered on the Platforms, we
        will takereasonable measures to ensure the validity of all information
        relating to transactions and products offered on the Platforms.
      </p>
      <p>
        We try to keep the Platforms available, bug-free and safe. However, you
        use the Platforms at your own risk. Further, we do not warrant or
        represent that access to the whole or part(s) of the Platforms, the
        materials, information and or functions contained in the same will be
        provided uninterrupted, timely, secure or error-free or that any
        identified defect will be corrected, or that there will be no delays,
        failures, errors or loss of transmitted information, that no viruses or
        other contaminating or destructive properties will be transmitted or
        that no damage will occur to your computer system. You understand and
        agree that any material and or data downloaded or otherwise obtained
        through the use of the Platforms or any of the materials contained in
        the same is done at your own discretion and risk. If your use of the
        Platforms or the Material results in damage and need for servicing or
        replacing equipment or data, we shall not be responsible for those costs
        and you will solely be responsible for any such damages. If the Material
        is provided by third parties, we shall not be held responsible for any
        such third-party material.
      </p>
      <p>
        The materials, information and functions provided on the Platforms shall
        not under any circumstances be considered or construed as an offer or
        solicitation to sell, give, issue or as the giving of any advice in
        respect of any form of investment or other securities, loans, or
        deposits in any jurisdiction. You shall be responsible to evaluate the
        quality, adequacy, timeliness and usefulness of all services, content,
        advice, opinions and other information obtained or accessible through
        the Platforms; further you should seek professional and or legal advice
        at all times and obtain independent verification of the materials and
        information provided on the Platforms prior to making any investment,
        business or commercial decision based on any such materials or
        information.
      </p>
      <p>
        No advice or information, whether oral or written, obtained by you
        through the Platforms or from iNECH or its suppliers (or the respective
        officers, directors, employees, or agents of any such entities) shall
        create any warranty.
      </p>
      <h3 id='section-7'>7. Links to Third Party Sites</h3>
      <p>
        We may provide links to Third Party websites. Such links do not
        necessarily imply any endorsement, agreement with, or support for, the
        information on or content of those Third-Party websites. We do not
        control the information or content on those Third Party Websites and
        neither we nor our affiliates makes any warranties in respect of the
        content of these websites nor will be responsible or liable in any
        manner whatsoever, for your access, or inability to access, such Third
        Party websites, or for any information or content, products or services
        made available on or through such Third Party websites, or for any loss,
        liability or damage which you may suffer as a result of access to a
        Third Party website through a link on the Platforms. You further
        acknowledge and agree that we shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be
        caused by or in connection with use of or reliance on any such
        third-party websites and access to any such third party sites is
        entirely at your own risk and subject to any terms and conditions and
        privacy policies posted therein.
      </p>
      <h3 id='section-8'>8. Violation of Terms and Conditions</h3>
      <p>
        If, in our sole discretion, we believe that you may have engaged in any
        violation of this T&C, we may with or without notice to you take such
        actions as we deem appropriate to mitigate risk to us and any impacted
        third parties and to ensure compliance with this T&C. Such actions may
        include, without limitation:
      </p>
      <ul className='bullet-outline'>
        <li>
          Suspending, restricting or terminating your access to and use of the
          Platforms and the services available on the same;
        </li>
        <li>
          Terminating our business relationship with you, including termination
          without liability to iNECH of any service agreement between you and
          iNECH;
        </li>
        <li>Taking legal action against you;</li>
        <li>
          Contacting and disclosing information related to such violations to
          law enforcement or regulatory agencies and other third parties that
          may have been impacted by such violations; or
        </li>
        <li>
          Assessing against you any fees, penalties, assessments or expenses
          including reasonable attorneys' fees that we may incur as a result of
          such violations, which you agree to pay promptly upon notice.
        </li>
      </ul>

      <h3 id='section-9'>9. Limitation of Liability</h3>
      <p>
        In no event shall iNECH or its directors, employees, licensors, agents,
        suppliers or service providers be liable to you or any other third party
        for any loss or damages howsoever arising whether in tort, contract,
        negligence or any other basis including but not limited to special,
        indirect, incidental, consequential, exemplary or punitive damages,
        damages for loss of profits, revenue, or good will, which may arise from
        your use or any person’s use, access, misuse or inability to use the
        Platforms or any links to third party websites or any of the Materials
        contained therein, reliance on the information contained on the
        Platforms, any technical, hardware or software failure of any kind, the
        interruption, error, omission, delay in operation, computer viruses,
        even if we have been advised of the possibility of such damages. iNECH
        shall not be liable for any damages whatsoever direct or indirect in
        respect of access to and use of the Platforms. In any event, iNECH will
        not be liable for any damages more than the amount of the transaction,
        which is the subject of any claim.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for certain damages.
        Accordingly, some of the above provisions of limitation of liabilities
        and exclusion of warranties may not apply to you. To the extent that we
        may not as a matter of applicable laws disclaim any implied warranty or
        limit our liabilities, the scope and duration of such warranty and the
        extent of our liability shall be the minimum permitted under such
        applicable laws.
      </p>
      <h3 id='section-10'>10. Indemnity</h3>
      <p>
        You hereby irrevocably agree to defend, indemnify, and hold harmless
        iNECH, its officers, directors, shareholders, employees, independent
        contractors and agents, from and against any and all claims, actions,
        liabilities, costs, or demands, including without limitation legal and
        accounting fees, for all damages directly, indirectly, and or
        consequentially resulting or allegedly resulting from your use, misuse,
        or inability to use the Platforms or any of the Materials contained
        therein, or your breach of any of the provisions in this T&C or any
        intellectual property right or proprietary right infringement claim made
        by a third party against iNECH in connection with your use of the
        Platforms. We shall promptly notify you of any such claim, proceeding or
        suit, and you shall be responsible for its defense at your expense.
        iNECH may participate in the defense of such claim or defense but are
        not obligated to do so.
      </p>
      <h3 id='section-11'>11. Force Majeure</h3>
      <p>
        We shall not be responsible for any failure to perform due to unforeseen
        circumstances or to causes beyond our reasonable control, including but
        not limited to: acts of God, such as fire, flood, earthquakes or other
        natural disasters; war, riot, arson, embargoes, acts of civil or
        military authority, or terrorism; fiber cuts; strikes, or shortages in
        transportation, facilities, fuel, energy, labor or materials; failure of
        the telecommunications or information services infrastructure; hacking,
        SPAM, epidemics, pandemics or any failure of a computer, server or
        software, for so long as such event continues to delay our Platforms
        performance.
      </p>
      <h3 id='section-12'>12. Severability, Non-Waiver and Entire Agreement</h3>
      <p>
        If any provision of this T&C is found to be invalid by any court having
        competent jurisdiction, the invalidity of such provision shall not
        affect the validity of the remaining provisions of this T&C, which shall
        remain in full force and effect. No waiver of any term of this T&C shall
        be deemed a further or continuing waiver of such term or any other term.
        Except as expressly provided in a particular web page, this T&C
        constitute the entire Agreement between you and us with respect to the
        use of the Platforms.
      </p>
      <h3 id='section-13'>13. Updates, Modifications & Amendments</h3>
      <p>
        We may need to update, modify or amend our T&C as our technology
        evolves. We reserve the right to make changes to this T&C at any time by
        giving notice to you on this page.
      </p>
      <p>
        We advise that you check this page often, referring to the date of the
        last modification on the page. If you object to any of the changes to
        this T&C, you must cease using the Platforms and or services
        immediately.
      </p>
      <h3 id='section-14'>14. Governing Law and Jurisdiction</h3>
      <p>
        This T&C and all matters arising out of or relating to this T&C shall be
        governed by and are to be construed in accordance with the laws of the
        Federal Republic of Nigeria.
      </p>
      <p>
        We shall make an effort to settle all disputes amicably. Any dispute
        arising out of this T&C, which cannot be settled, by mutual agreement
        within a period of one (1) month from the time such dispute arose shall
        be referred to arbitration by a single arbitrator at the Lagos Court of
        Arbitration (“LCA”) and governed by the Lagos State Arbitration Law
        2009. The arbitrator shall be appointed by both of us (you and us).
        Where both of us are unable to agree on the choice of an arbitrator, the
        choice of arbitrator shall be referred to the Chairman of the Chartered
        Institute of Arbitrator, UK (Nigerian branch) on application by us or
        you. The findings of the arbitrator and subsequent award shall be
        binding on both of us. Each of us shall bear our respective costs in
        connection with the arbitration. Venue for the arbitration shall be
        Lagos, Nigeria.
      </p>
    </div>
  );
}

export default TermsText;
