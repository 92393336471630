import call_to_action from "../../../assets/images/call-to-action.svg";
import call_to_action_mobile from "../../../assets/images/call-to-action-mobile.svg";
import "./solution_five.css";

function SolutionFive() {
  return (
    <div className='container pt-5'>
      <div className='pt-4'></div>
      <div className='d-none d-md-block pt-4' />
      <center>
        <div className='d-md-none'>
          <img
            className=''
            width='100%'
            src={call_to_action_mobile}
            alt=''
            srcSet=''
          />
        </div>
        <div className='d-none d-md-block'>
          <img
            className='call-to-action-img'
            src={call_to_action}
            alt=''
            srcSet=''
          />
        </div>
      </center>
      <div className='pt-5'></div>
    </div>
  );
}

export default SolutionFive;
