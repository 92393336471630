import about_image from "../../../assets/images/about-image.svg"
import about_image_mobile from "../../../assets/images/about-image-mobile.png"
import vector_outline from "../../../assets/images/vector-outline.svg"
import "./body.css";

function AboutBody () {
    return (
        <div>
            <div className="pt-5 d-none d-md-block"/>
            <div className="pt-4 d-none d-md-block"/>
            <div className="pt-5 d-md-none"/>

            <center>
            <div className='d-none d-md-block'>
                <img src={about_image} width={'100%'} alt="" srcSet="" />
            </div>
            <div className='d-md-none'>
                <img src={about_image_mobile} width={'70%'} alt="" srcSet="" />
            </div>
            </center>
            <div className="pt-5"/>
            <div className="pt-5 d-none d-md-block"/>

            <center>
            <div className="ibody-text"><p>Welcome to iNPAY, your innovative partner in digital finance. Founded on the principles of simplicity, transparency, 
                and empowerment, iNPAY is dedicated to revolutionizing the way individuals and businesses manage their financial transactions within Nigeria.</p>
                <p></p></div>
            </center>

            <div className="pt-5"/>
            <div className="pt-2"/>


            <center>
                <div className="ibody-title"><p>iNPAY’s mission</p></div>
                <div className="ibody-text">
                <p>
                Our mission is to bridge the gap in digital payments by providing a seamless, secure, and efficient platform that caters 
                to the needs of diverse users across Nigeria. Whether you're a busy professional, a small business owner, or a student, 
                iNPAY is designed to make financial transactions easier and more accessible.
                </p>
                    </div>
            </center>


            <div className="pt-5"/>
            <div className="pt-2"/>


            <center>
                <div className="ibody-title"><p>iNPAY’s Vision</p></div>
                <div className="ibody-text">
                <p>
                We envision a Nigeria where financial transactions are frictionless and inclusive, enabling everyone to manage their money with 
                confidence and ease. By harnessing cutting-edge technology and user-centric design, we aim to set a new standard in the digital 
                payments industry.
                </p>
                    </div>
            </center>


            <div className="pt-5"/>
            <div className="pt-4"/>

            <div className="inpay-container">
                <img className= "vector-outline" src={vector_outline} alt="vector" srcset="" />
                <div className="pt-3 d-none d-md-block"/>
                <div className="pt-5 d-md-none"/>
                <div className="inpay-container-title"><p>Our Core Values</p></div>
                <div className="pt-5 d-none d-md-block"/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            <div className="rounded-number">
                                <p>1</p>
                            </div>
                            <div className="pt-3"/>
                           <div className="inpay-container-title2">Innovation</div>
                            <div className="inpay-container-text"> At iNPAY, we are committed to continually evolving and improving our platform to meet the changing needs of our users. 
                                Our innovative features are designed to provide the best possible user experience.
                            </div>

                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="rounded-number">
                                <p>2</p>
                            </div>
                            <div className="pt-3"/>
                           <div className="inpay-container-title2">Transparency</div>
                            <div className="inpay-container-text">We believe in complete transparency. All our fees and charges are clearly stated upfront, 
                                ensuring there are no surprises for our users.
                            </div>

                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="rounded-number">
                                <p>3</p>
                            </div>
                            <div className="pt-3"/>
                           <div className="inpay-container-title2">Security</div>
                            <div className="inpay-container-text">Protecting your financial information is our top priority. We use advanced encryption and security 
                                protocols to ensure that your data is safe and secure at all times.
                            </div>

                        </div>

                        <div className="col-md-3 col-sm-12">
                            <div className="rounded-number">
                                <p>4</p>
                            </div>
                            <div className="pt-3"/>
                           <div className="inpay-container-title2">Convenience</div>
                            <div className="inpay-container-text"> Our platform is designed to be user-friendly and accessible, allowing you to manage your finances anytime, 
                                anywhere within Nigeria. Whether you need to send money, pay for goods and services, or manage shared expenses, iNPAY makes it simple and convenient.

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5 d-none d-md-block"/>
            <div className="pt-5"/>


            <center>
                <div className="ibody-title"><p>Join the iNPAY train </p></div>
                <div className="ibody-text">
                <p>
                We invite you to join the iNPAY community and experience the future of digital payments in Nigeria. 
                Whether you're managing personal finances or running a business, iNPAY offers the tools and features you need to succeed. 
                Our team is dedicated to providing exceptional support and continually enhancing our platform to meet your needs.
                </p>
                    </div>
            </center>


<br />
<br />

        </div>
    )
}

export default AboutBody;