import "./user-form.css";
import Select from "react-dropdown-select";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";

function UserForm() {
  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({
    category: options[0]["name"],
    fullName: "",
    subject: "",
    message: "",
    email: "",
  });
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop: (acceptedFiles) => {
      if (files.length >= 2) return;
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign({}, file, { name: file.name })
        ),
      ]);
    },
    maxFiles: 2,
  });

  const recaptchaRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isVerified) {
      setErrorMessage("Please verify you are not a robot.");
      return;
    }

    const formDataWithFiles = new FormData();
    files.forEach((file) => formDataWithFiles.append("files", file));
    Object.entries(formData).forEach(([key, value]) =>
      formDataWithFiles.append(key, value)
    );

    // Process form data here (e.g., validation, API call)
    //   const response = await fetch('/upload', {
    //     method: 'POST',
    //     body: formDataWithFiles
    //   });

    // Reset reCAPTCHA for future submissions
    setFiles([]);
    setFormData({
      category: options[0]["name"],
      fullName: "",
      subject: "",
      message: "",
      email: "",
    });
    recaptchaRef.current.reset();
  };

  const handleVerify = (value) => {
    setIsVerified(true);
    setErrorMessage(""); // Clear any previous error messages
  };

  function setValues(values) {
    setFormData({ ...formData, category: values[0]["name"] });
  }

  const handleRemoveFile = (file) => {
    const updatedFiles = files.filter((f) => f !== file);
    setFiles(updatedFiles);
  };

  const iconStyle = {
    position: "relative", // Enable relative positioning for shadows
    display: "inline-block", // Allow inline element styling
    width: "17px", // Adjust width as needed
    color: "red",
    height: "17px", // Adjust height as needed
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
    transition: "transform 0.1s ease-in-out", // Enable smooth transition for 3D effect
  };

  return (
    <div>
      <br />
      <br />
      <div className='container iw2 mt-3'>
        <div className='row'>
          <form>
            <div className='col-12'>
              <div className='userform-label'>Please choose category below</div>
              <div className='mt-3 emailField3'>
                <Select
                  options={options}
                  className='custom-select-dropdown'
                  placeholder='General Support'
                  labelField='name'
                  valueField='id'
                  searchable={false}
                  style={{ borderRadius: "5px", border: "1px solid #63626263" }}
                  onChange={(values) => setValues(values)}
                />
              </div>
              <br />
            </div>
            <div className='pt-3 d-none d-md-block' />

            <div className='col-12'>
              <div className='userform-label'>Your email address</div>
              <div className='input-group mt-3 emailField2'>
                <input
                  type='text'
                  required
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  style={{ border: "none", fontSize: 18 }}
                  className='form-control email-roundBorder  ib'
                  placeholder='Email Address'
                  aria-label='Enter email address'
                  aria-describedby='basic-addon2'
                />
              </div>
              <div className='p-2 d-none' />
            </div>
            <div className='pt-3 d-md-none' />
            <div className='pt-5 d-none d-md-block' />

            <div className='col-12'>
              <div className='userform-label'>Your full name</div>
              <div className='input-group mt-3 emailField2'>
                <input
                  type='text'
                  required
                  name='fullName'
                  value={formData.fullName}
                  onChange={handleChange}
                  style={{ border: "none", fontSize: 18 }}
                  className='form-control email-roundBorder ib'
                  placeholder='Full name'
                  aria-label='Enter your full name'
                  aria-describedby='basic-addon2'
                />
              </div>
              <div className='p-2 d-none d-md-block' />
            </div>
            <div className='pt-3 d-md-none' />
            <div className='pt-4 d-none d-md-block' />

            <div className='col-12'>
              <div className='userform-label'>Subject</div>
              <div className='input-group mt-3 emailField2'>
                <input
                  type='text'
                  required
                  name='subject'
                  value={formData.subject}
                  onChange={handleChange}
                  style={{ border: "none", fontSize: 18 }}
                  className='form-control email-roundBorder ib'
                  placeholder='Subject'
                  aria-label='Enter subject'
                  aria-describedby='basic-addon2'
                />
              </div>

              <div className='pt-2' />
            </div>
            <div className='pt-3 d-md-none' />
            <div className='pt-4 d-none d-md-block' />

            <div className='col-12'>
              <div className='userform-label'>Message</div>
              <div className='input-group mt-3 textField'>
                <textarea
                  type='text'
                  required
                  name='message'
                  value={formData.message}
                  onChange={handleChange}
                  style={{ fontSize: 18 }}
                  className='form-control email-roundBorder  ib-textarea'
                  placeholder='Message'
                  rows='5'
                  aria-label='Enter subject'
                  aria-describedby='basic-addon2'
                />
              </div>
              <div className='pt-4' />
            </div>
            <div className='pt-2 d-md-none' />
            <div className='pt-4 d-none d-md-block' />
            <div className='col-12'>
              <div className='userform-label'>Attachment (Optional)</div>
              <div className='attachment-container mt-3'>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p style={{ cursor: "pointer" }}>
                    <span>Add file</span> or drop file
                  </p>
                </div>
              </div>
              <div className='pt-4' />

              <ul className='files-list'>
                {files.map((file) => (
                  <div className='files-container'>
                    <li key={file.name}>
                      <span>{file["name"]}</span>
                      <button
                        className='user-form-close-button'
                        onClick={() => handleRemoveFile(file)}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "translateY(-1px)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "none")
                        }
                      >
                        <CloseIcon style={iconStyle} />
                      </button>
                    </li>
                  </div>
                ))}
              </ul>
            </div>

            <div className='col-12'>
              <div className='pt-4' />

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey='6LducPEpAAAAAICLZWpZHHn_r0BZaSqfNwixroca'
                size='normal'
                onChange={handleVerify}
              />

              {errorMessage && <div className='pt-4' />}

              {errorMessage && <span className='error'>{errorMessage}</span>}
            </div>
          </form>
        </div>

        <br />
        <br />
        <br />
        <div className='submit-div'>
          <button
            type='submit'
            className='email-roundBorder submit-button'
            style={{ border: "none" }}
            onClick={handleSubmit}
            id='basic-addon2'
          >
            Submit
          </button>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

const options = [
  {
    id: 1,
    name: "General Support",
  },
  {
    id: 2,
    name: "Help",
  },
  {
    id: 3,
    name: "Inquiry",
  },
];
export default UserForm;
