import "./footer.css";
import { Link } from "react-router-dom";
import instagram from "../../assets/images/instagram.svg";
import x from "../../assets/images/x.svg";
import tiktok from "../../assets/images/tiktok.svg";
import youtube from "../../assets/images/youtube.svg";
import { useState } from "react";

function Footer() {
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [mailError, setMailError] = useState(false);

  function handleEmail(email) {
    setEmail(email);
    emailTest(email);
    if (!isEmail) {
      if (email.length > 2) setMailError(true);
      if (email.length === 0) setMailError(false);
      return;
    } else {
      setMailError(false);
      return;
    }
  }

  function emailTest(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isMail = re.test(email);
    if (isMail) {
      setIsEmail(true);
      return true;
    } else {
      setIsEmail(false);
      return false;
    }
  }

  return (
    <div className='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-6 col-md-2'>
            <br className='d-md-none' />
            <br className='d-md-none' />
            <div className='footerTitle'>Company</div>
            <div className='footerDescription'>
              <Link to='/about-us' className='plain-link'>
                About
              </Link>
            </div>
            <div className='footerDescription'>
              <Link to='/contact-us' className='plain-link'>
                Contact
              </Link>
            </div>
            <div className='footerDescription'>Blog</div>
          </div>
          <div className='col-6 col-md-2'>
            <br className='d-md-none' />
            <br className='d-md-none' />
            <div className='absl'>
              <div className='footerTitle'>Product</div>
              <div className='footerDescription'>Account Management</div>
              <div className='footerDescription'>Wallet Management</div>
              <div className='footerDescription'>Scan to Pay QR Code</div>
            </div>
          </div>
          <div className='col-sm-12 col-md-2'>
            <br className='d-md-none' />
            <br className='d-md-none' />
            <div className='footerTitle'>Legal</div>
            <div className='footerDescription'>
              <Link to='/terms-service' className='plain-link'>
                Terms of service
              </Link>
            </div>
            <div className='footerDescription'>
              <Link to='/terms-use' className='plain-link'>
                Terms of use
              </Link>
            </div>
            <div className='footerDescription'>
              <Link to='/privacy' className='plain-link'>
                Privacy policy
              </Link>
            </div>
            <div className='footerDescription'>
              <Link to='/acceptable-use' className='plain-link'>
                Acceptable use policy
              </Link>
            </div>
          </div>
          <div className='col-sm-12 col-md-1' />
          <div className='col-sm-12 col-md-5'>
            <center>
              <br className='d-md-none' />
              <br className='d-md-none' />
              <div className='footerTitle'>Sign up for our newsletter</div>
              <form
                method='POST'
                id='zcampaignOptinForm'
                action='https://wmaz-zgfl.maillist-manage.com/weboptin.zc'
                target='_zcSignup'
              >
                <div className='SIGNUP_FLD input-group mt-3 footerEmailField'>
                  <input
                    type='text'
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    required
                    placeholder='Enter email'
                    className='form-control roundBorder'
                    style={{ border: "none", fontSize: 16 }}
                    changeitem='SIGNUP_FORM_FIELD'
                    name='CONTACT_EMAIL'
                    id='EMBED_FORM_EMAIL_LABEL'
                  />

                  <div className='SIGNUP_FLD input-group-append'>
                    <input
                      type='submit'
                      className='input-group-text roundBorder button'
                      disabled={!isEmail}
                      style={{ border: "none" }}
                      name='SIGNUP_SUBMIT_BUTTON'
                      id='zcWebOptin'
                      value='Subscribe'
                    />
                  </div>
                </div>

                <input type='hidden' id='fieldBorder' value='' />
                <input
                  type='hidden'
                  id='submitType'
                  name='submitType'
                  value='optinCustomView'
                />
                <input
                  type='hidden'
                  id='emailReportId'
                  name='emailReportId'
                  value=''
                />
                <input
                  type='hidden'
                  id='formType'
                  name='formType'
                  value='QuickForm'
                />
                <input type='hidden' name='zx' id='cmpZuid' value='132f070e9' />
                <input type='hidden' name='zcvers' value='3.0' />
                <input
                  type='hidden'
                  name='oldListIds'
                  id='allCheckedListIds'
                  value=''
                />
                <input
                  type='hidden'
                  id='mode'
                  name='mode'
                  value='OptinCreateView'
                />
                <input
                  type='hidden'
                  id='zcld'
                  name='zcld'
                  value='1b20d99c88a94f43'
                />
                <input type='hidden' id='zctd' name='zctd' value='' />
                <input type='hidden' id='document_domain' value='' />
                <input
                  type='hidden'
                  id='zc_Url'
                  value='wmaz-zgfl.maillist-manage.com'
                />
                <input type='hidden' id='new_optin_response_in' value='0' />
                <input
                  type='hidden'
                  id='duplicate_optin_response_in'
                  value='0'
                />
                <input
                  type='hidden'
                  name='zc_trackCode'
                  id='zc_trackCode'
                  value='ZCFORMVIEW'
                />
                <input
                  type='hidden'
                  id='zc_formIx'
                  name='zc_formIx'
                  value='3z9772cf006a22068b9d9a66cf93ea6e68d2f50938e25a7e02f3d1dcf064ce85cf'
                />
                <input type='hidden' id='viewFrom' value='URL_ACTION' />
                <input
                  type='hidden'
                  id='scriptless'
                  name='scriptless'
                  value='yes'
                />
                <input
                  type='hidden'
                  id='zc_spmSubmit'
                  name='zc_spmSubmit'
                  value='ZCSPMSUBMIT'
                />
              </form>
            </center>
            <div style={{ marginTop: "40px", marginLeft: "20px" }}>
              <span
                style={{
                  textAlign: "left",
                  color: "red",
                  fontSize: "13px",
                  fontFamily: "DM Sans",
                }}
              >
                {mailError && "Please enter a valid email"}
              </span>
            </div>
          </div>

          <div className='col-12'>
            <br />
            <br />
            <div className='navbar '>
              <div className='navbar'>
                <img src={instagram} className='footerIcon' alt='' />
                <img src={x} className='footerIcon' alt='' />
                <img src={tiktok} className='footerIcon' alt='' />
                <img src={youtube} className='footerIcon' alt='' />
              </div>
              <div className='copyright'>
                © 2024, iNTECH All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
